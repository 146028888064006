<template>
  <div class="mt-3">
    <h1 class="mb-3">
      {{ $t('cp.t') }}
    </h1>
    <b-row>
      <b-col class="text-justify">
        <h5>{{ $t('cp.hov') }}</h5>
        <p>
          <i18n path="cp.ot1" tag="span">
            <template #link><b-link to="imprint">{{ $t('cp.ot1l') }}</b-link></template>
          </i18n>
        </p>
        <p>
          {{ $t('cp.ot2') }}
        </p>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="text-justify">
        <h5 id="privacy">{{ $t('cp.heg') }}</h5>
        <b-list-group class="pb-3">
          <b-list-group-item>
            <h6 class="font-weight-bold">{{ $t('cp.hre', [1]) }}</h6>
            <b-row>
              <b-col cols="6">
                <address class="text-left">
                  Anures GmbH<br>
                  Pappelallee 78/79<br>
                  10437 Berlin<br>
                  {{ $t('cntry.de') }}
                </address>
              </b-col>
              <b-col cols="6">
                <p class="text-left">
                  {{ $t('cp.ce1', ['C. Dombrowski']) }}<br>
                  <i18n path="cp.ce2">
                    <template #link>
                      <b-link href="mailto:privacy@anures.com">privacy@anures.com</b-link>
                    </template>
                  </i18n><br>
                  {{ $t('cp.ce3', ['+49 163 773 5 373']) }}
                </p>
              </b-col>
            </b-row>
          </b-list-group-item>
          <b-list-group-item>
            <h6 class="font-weight-bold">{{ $t('cp.hri', [2]) }}</h6>
            <p>{{ $t('cp.ci1') }}</p>
            <ul class="mt-n2">
              <li v-for="i in 6" :key="`ulr.`+i">{{ $t('cp.ci1'+i) }}</li>
            </ul>
            <p><strong>{{ $t('cp.ci2h1') }}</strong></p>
            <p>{{ $t('cp.ci2t') }}</p>
            <p><strong>{{ $t('cp.ci2h2') }}</strong></p>
            <p>{{ $t('cp.ci2t1') }}</p>
            <p><u>{{ $t('cp.ci2t2') }}</u></p>
            <p>{{ $t('cp.ci2t3') }}</p>
          </b-list-group-item>
          <b-list-group-item>
            <h6 class="font-weight-bold">{{ $t('cp.hde', [3]) }}</h6>
            <p>{{ $t('cp.ci31') }}</p>
            <ul class="mt-n2">
              <li v-for="i in 3" :key="`ulr.`+i">{{ $t('cp.ci3t'+i) }}</li>
            </ul>
            <p>{{ $t('cp.ci32') }}</p>
          </b-list-group-item>
          <b-list-group-item v-for="(it, idx) in entries" :key="`l.`+idx" :id="it.id?it.id:`l.`+idx">
            <h6 class="font-weight-bold">{{ $t('cp.h' + idx, [idx+4]) }}</h6>
            <p class="text-justify" v-if="it.s===true"><i18n :path="'cp.c'+idx+'s'"><br></i18n></p>
            <template v-for="(sit, sidx) in it.x">
              <p :key="`px`+idx+`.`+sidx" class="text-justify">{{ $t('cp.c'+idx+'x'+sidx) }}</p>
              <ul class="mt-n2" :key="`ulx`+idx+`.`+sidx">
                <li v-for="i in sit" :key="`ulx`+idx+`.`+sidx+`.`+i">{{ $t(['cp.c',idx,'x',sidx,i].join('')) }}</li>
              </ul>
            </template>
            <template v-if="it.link!=null">
              <p><b-link @click="it.link">{{ $t('cp.c'+idx+'lt') }}</b-link></p>
            </template>
            <template v-if="parseFloat(it.d)===it.d">
              <p class="text-justify font-weight-bold">{{ $t('cp.ld') }}</p>
              <ul class="text-justify mt-n2">
                <li v-for="i in it.d" :key="`ul.`+idx+`.`+i">{{ $t(['cp.c',idx,'d',i].join('')) }}</li>
              </ul>
            </template>
            <template v-if="it.u===true">
              <p class="text-justify"><strong>{{ $t('cp.lu') }}</strong> {{ $t('cp.c'+idx+'u') }}</p>
            </template>
            <template v-if="it.p===true">
              <p class="text-justify"><strong>{{ $t('cp.lp') }}</strong> {{ $t('cp.c'+idx+'p') }}</p>
            </template>
            <template v-if="it.l===true">
              <p class="text-justify"><strong>{{ $t('cp.ll') }}</strong> <i18n :path="'cp.c'+idx+'l'"><br></i18n></p>
            </template>
          </b-list-group-item>
        </b-list-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <h5>{{ $t('cp.hvs') }}</h5>
        <p class="text-justify">
          {{ $t('cp.lv', ['1']) }}
          <i18n path="cp.lvt">
            <template #date>{{ $d(new Date(2021,4,1), 'ds') }}</template>
          </i18n>
        </p>
      </b-col>
    </b-row>
    <CookieCheckContent v-if="cookieClick" @submit="cookieReset()" @reset="cookieReset()" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      entries: [
        // starttext, data, user, purpose, law
        {s: true, d: 4, u: true, p: true, l: true},
        {s: true, x: [2, 2], d: 2, u: true, p: true, l: true, id: 'cookies', link: ()=>this.cookieShow()},
        {s: true, x: [9, 3], d: 3, u: true, p: true, l: true},
        {s: true, x: [3], d: 3, u: true, p: true, l: true},
        {s: true, d: 4, u: true, p: true, l: true},
        {s: true, d: 5, u: true, p: true, l: true},
        {s: true, x: [2, 2], d: 4, u: true, p: true, l: true},
        {s: true, x: [3], d: 5, u: true, p: true, l: true},
        {s: true, x: [3, 2], d: 5, u: true, p: true, l: true},
        {s: true, x: [2], d: 3, u: true, p: true, l: true},
        {s: true, d: 2, u: true, p: true, l: true},
        {s: true, d: 2, u: true, p: true, l: true},
        {s: true},
      ],
      cookieClick: false,
    };
  },
  methods: {
    cookieShow() {
      this.cookieClick = true;
    },
    cookieReset() {
      this.cookieClick = false;
    },
  },
  components: {
    CookieCheckContent: () => import('@/components/scripts/CookieCheckContent'),
  },
}
</script>
